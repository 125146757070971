<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('container-box.customs-price-manage')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.total_cubic_feet="{ item }">
          {{ fixedFloat(item.total_cubic_feet, 2, 0) }}
        </template>
        <template v-slot:item.export="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                class="mr-3"
                @click="exportApplyList(item)"
              >
                {{ $t("apply_list") }}
              </v-btn>
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="viewItem(item)"
              >mdi-eye</v-icon>
            </template>
            <span>{{ $t("view") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import BaseTable from "./BaseTable.vue";
export default {
  data: (vm) => ({
    url: "/container/container/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.order_number"),
        value: "order_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_box_amount"),
        value: "total_box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.total_cubic_feet"),
        value: "total_cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "stuffing_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "cutoff_date",
      },
      {
        sortable: false,
        text: vm.$i18n.t("export"),
        value: "export",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
      },
    ],
  }),
  mixins: [BaseTable],
  components: {
    CommonAlertDialog
  },
  methods: {
    viewItem(item){
      this.$router.push({
        name: "CustomsPriceDetail",
        query: {
          container: item.id,
          name: item.container_number
        }
      });
    },
    exportApplyList(item) {
      const url = "/container/container/" + item.id + "/getApplyList/";
      this.getFileApi(url, "報關清單.xlsx");
    },
  },
  mounted() {
    this.getParams['status'] = 1
  }
};
</script>